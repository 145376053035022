<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listMoveContactDetail,
  sumListMoveContactDetail,
  listMoveContact,
  sumListMoveContact,
} from "@/api/store/report"; //会员充值接口
const typesList = ["140306", "130105", "170407", "170307"];
export default {
  name: "moveContact",
  components: {
    TablePage,
  },
  data() {
    return {
      allRouter: {
        140305: "MoveInDetail", // 门店调入
        140306: "MoveOutDetail", // 门店调出
        130105: "storeMoveOutDetail", // 仓库调出
        130106: "storeMoveInDetail", // 仓库调入
        170306: "DecorationMoveInDetail", // 裱花调入
        170307: "DecorationMoveOutDetail", // 裱花调出
        170406: "FreshlyBakedMoveInDetail", // 现烤调入
        170407: "FreshlyBakedMoveOutDetail", // 现烤调出
      },
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "仓库调拨往来查询",
        rowKey: "id",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/单据编号/商品编码/商品条码/商品名称/备注",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "barcodes", label: "商品条码" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "remarks", label: "备注" },
            ],
          },
          this.$select({
            key: "billTypeList",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                dataKey: (res) => res.data.filter((x) => typesList.includes(x.dictValue)),
              },
            },
          }),
          this.$select({
            key: "billCustomType",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listInStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "detail",
        tabsColumns: [
          {
            title: "仓库调拨往来明细查询",
            type: "detail",
            getListApi: listMoveContactDetail,
            getSummaryApi: sumListMoveContactDetail,
            exportOption: {
              ossKeyUrl: "api/system/store/reportForms/getExportlistMoveContactDetail",
              fastExportUrl: "api/system/store/reportForms/exportlistMoveContactDetail",
              exportName: "仓库调拨往来明细查询",
            },
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "billCustomTypeName",
                label: "业务类型",
                minWidth: 120,
              },
              {
                prop: "billDate",
                label: "调拨出库日期",
                minWidth: 120,
              },
              {
                prop: "billNo",
                label: "调拨出库单号",
                type: "link",
                click: "routerLinkMoveOut",
                minWidth: 150,
              },
              {
                prop: "moveInBillDate",
                label: "调拨入库日期",
                minWidth: 150,
              },
              {
                prop: "moveInBillNo",
                label: "调拨入库单号",
                type: "link",
                click: "routerLinkMoveIn",
                minWidth: 150,
              },
              {
                prop: "moveInBillStatusName",
                label: "调拨入库状态",
                minWidth: 120,
              },
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 150,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 180,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 130,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 100,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "outUnitPrice",
                label: "调出单价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveOutQty",
                label: "出库数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveInQty",
                label: "入库数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveOutMoney",
                label: "出库金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveInMoney",
                label: "入库金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "billRemark",
                label: "单据备注",
                minWidth: 150,
              },
              {
                prop: "billAutoRemark",
                label: "自动备注",
                minWidth: 150,
              },
              {
                prop: "remark",
                label: "行备注",
                minWidth: 150,
              },
            ],
            summary: ["moveOutQty", "moveInQty", "moveOutMoney", "moveInMoney"],
          },
          {
            title: "仓库调拨往来汇总查询",
            type: "sum",
            getListApi: listMoveContact,
            getSummaryApi: sumListMoveContact,
            exportOption: {
              ossKeyUrl: "api/system/store/reportForms/getExportlistMoveContact",
              fastExportUrl: "api/system/store/reportForms/exportlistMoveContact",
              exportName: "仓库调拨往来汇总查询",
            },
            columns: [
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 120,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 180,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 130,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 100,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveOutQty",
                label: "出库数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveInQty",
                label: "入库数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveOutMoney",
                label: "出库金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "moveInMoney",
                label: "入库金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["moveOutQty", "moveInQty", "moveOutMoney", "moveInMoney"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "routerLinkMoveOut": // 调拨出库单号 跳转
          console.log(type, row, "type, row");
          {
            let name = this.allRouter[Number(row.billType)];
            if (!name || !row.billId) return;
            this.$router.push({
              name: name,
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }
          break;
        case "routerLinkMoveIn": // 调拨入库单号 跳转
          {
            let name = this.allRouter[Number(row.moveInBillType)];
            if (!name || !row.moveInBillId) return;
            this.$router.push({
              name: name,
              query: {
                billId: row.moveInBillId,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
