<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { transferConfirm } from "@/api/store/report";
import { allStoreList } from "@/api/system/store"; //仓库
import { ALLOT_STATUS_TEXT } from "@/utils/constant.js";
import { getOrderDetail } from "@/api/shop/bill/shopBills";

export default {
  name: "moveAffirm",
  components: { TablePage },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        uuid:'b8f1f94d-764b-a6b5-c62e-0cdb90e85eae',
        getListApi: transferConfirm,
        body: {},
        title: "仓库调拨确认",
        rowKey: "billNo",
        exportOption: {
          show: true,
          exportApi: 'exportTransferConfirm',
          exportName: '仓库调拔确认表',
          timeout: 60*5*1000
        },
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ startBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据编号",
            type: "input",
            model: "",
            filter: "billNo",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级搜索",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
          this.$select({
            key: "listOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listInStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
        ],
        operates: {
          width: 80,
          list: [
            {
              text: "立即处理",
              method: (row) => {
                // inBillId  update
                let query = {}
                if (row.inBillId) {
                  query.type = 'Update'
                  query.billId = row.inBillId
                } else {
                  query.type = 'Add'
                  query.billId = row.billId
                }
                this.$router.push({
                  name: 'storeMoveInDetail',
                  query: query
                })
                // this.$router.push({
                //   name: 'storeMoveInDetail',
                //   query: {
                //     billId: billId,
                //     type: 'Update'
                //   }
                // })
              }
            },
          ],
        },
        columns: [
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 110,
          },
          {
            prop: "billNo",
            label: "调拨单据编号",
            minWidth: 180,
          },
          {
            prop: "outStoreName",
            label: "调出仓库",
            minWidth: 110,
          },
          {
            prop: "inStoreName",
            label: "调入仓库",
            minWidth: 110,
          },
          {
            prop: "billMoney",
            label: "单据金额",
            minWidth: 90,
          },
          {
            prop: "inBillStatus",
            label: "调入单据状态",
            formatter: (v) => ALLOT_STATUS_TEXT[v],
            minWidth: 100,
          },
          {
            prop: "billStatus",
            label: "调出单据状态",
            formatter: (v) => ALLOT_STATUS_TEXT[v],
            minWidth: 100,
          },
          {
            prop: "inBillDate",
            label: "引入单据日期",
            minWidth: 100,
          },
          {
            prop: "inBillNo",
            label: "引入单据编号",
            minWidth: 100,
          },
          
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 90,
          },
        ],
        list: [],
      }
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
