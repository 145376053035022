<template>
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-if="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePage"
      v-if="!showList"
      v-model="optionsDetail"
      @handleEvent="handleEvent"
    />
    <StockQueryDetail
      :openStockQuery.sync="openStockQuery"
      :goodsDetailData="goodsDetailData"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  storeInventoryGoodsList,
  sumStoreInventoryGoodsList,
  storeInventoryGoodsDetail,
} from "@/api/storeReport"; //接口api
import {
  listBookStatisticsByStoreGoodsDay,
  sumListBookStatisticsByStoreGoodsDay,
} from "@/api/system/store"; //仓库
// import StockQueryDetail from "./components/stockQueryDetail.vue"; //商品库存详情明细对话框组件
export default {
  name: "jxcList",
  components: { TablePage },
  data() {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: "10a65ba0-229d-7555-944f-40f02c44396f",
        body: {},
        tableTitle: "商品进销存一览表",
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBookDate: a, endBookDate: b }),
            hide: () => this.options?.tabColumnType === "day",
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            hide: () => this.options?.tabColumnType === "day",
          },
          {
            type: "filters",
            tip:
              "全部、商品编号、商品名称、条码、商品类别名称、商品类别编号、仓库名称、仓库编号",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号", group: "goodsQuery" },
              { filter: "goodsNames", label: "商品名称", group: "goodsQuery" },
              { filter: "barcodes", label: "条码", group: "goodsQuery" },
              { filter: "categoryNames", label: "商品类别名称", group: "categoryQuery" },
              { filter: "categoryNos", label: "商品类别编号", group: "categoryQuery" },
              { filter: "storeNames", label: "仓库名称", group: "storeQuery" },
              { filter: "storeNos", label: "仓库编号", group: "storeQuery" },
            ],
            hide: () => this.options?.tabColumnType === "day",
          },
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
              hide: () => this.options?.tabColumnType === "day",
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
              hide: () => this.options?.tabColumnType === "day",
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
              hide: () => this.options?.tabColumnType === "day",
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              option: {
                multiple: true,
              },
              hide: () => this.options?.tabColumnType === "sum",
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              option: {
                multiple: true,
              },
              hide: () => this.options?.tabColumnType === "sum",
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              filter: "storeIds",
              option: {
                multiple: true,
              },
              hide: () => this.options?.tabColumnType === "sum",
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
            hide: () => this.options?.tabColumnType === "day",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "sum",
        tabsColumns: [
          {
            title: "商品库存查询",
            type: "sum",
            getListApi: storeInventoryGoodsList,
            getSummaryApi: sumStoreInventoryGoodsList,
            exportOption: {
              show: true,
              exportApi: "exportGoodsPurSaleStockList",
              exportName: "商品库存查询一览表",
              timeout: 60 * 5 * 1000,
            },
            columns: [
              { label: "仓库", prop: "storeName", minWidth: 120, fixed: true },
              {
                label: "商品编码",
                prop: "goodsNo",
                minWidth: 120,
                fixed: true,
                link: true,
                click: "oepnDetail",
              },
              { label: "商品名称", prop: "goodsName", minWidth: 100 },
              { label: "条码", prop: "barcode", minWidth: 120 },
              { label: "商品类别", prop: "categoryName", minWidth: 100 },
              { label: "批号", prop: "batchNo", minWidth: 80 },
              { label: "规格", prop: "goodsSpec", minWidth: 80 },
              { label: "基础单位", prop: "unitName", minWidth: 80 },
              { label: "期初数", prop: "firstQty", minWidth: 120 },
              { label: "期初金额", prop: "firstMoney", minWidth: 120 },
              { label: "入库数", prop: "inQty", minWidth: 120 },
              { label: "入库金额", prop: "inMoney", minWidth: 120 },
              { label: "出库数", prop: "outQty", minWidth: 120 },
              { label: "出库金额", prop: "outMoney", minWidth: 120 },
              { label: "期末数", prop: "finalQty", minWidth: 120 },
              { label: "期末金额", prop: "finalMoney", minWidth: 120 },
              {
                label: "入库",
                prop: "billDate",
                children: [
                  { label: "采购数", prop: "purQty", minWidth: 100 },
                  { label: "采购金额", prop: "purMoney", minWidth: 100 },
                  { label: "仓库调入数", prop: "moveInQty", minWidth: 100 },
                  { label: "仓库调入金额", prop: "moveInMoney", minWidth: 100 },
                  { label: "库存调整入库数", prop: "adjustInQty", minWidth: 120 },
                  { label: "库存调整入库金额", prop: "adjustInMoney", minWidth: 130 },
                  { label: "销售退货数", prop: "saleBackQty", minWidth: 100 },
                  { label: "销售退货金额", prop: "saleBackMoney", minWidth: 100 },
                  { label: "仓库盘盈数", prop: "fullQty", minWidth: 100 },
                  { label: "仓库盘盈金额", prop: "fullMoney", minWidth: 100 },
                  { label: "生产退料数", prop: "backMaterialQty", minWidth: 100 },
                  { label: "生产退料金额", prop: "backMaterialMoney", minWidth: 100 },
                  { label: "产品入库数", prop: "produceInQty", minWidth: 100 },
                  { label: "产品入库金额", prop: "produceInMoney", minWidth: 100 },

                  { label: "组装入库数", prop: "combinInQty", minWidth: 130 },
                  { label: "组装入库金额", prop: "combinInMoney", minWidth: 130 },
                  { label: "拆卸入库数", prop: "stripInQty", minWidth: 100 },
                  { label: "拆卸入库金额", prop: "stripInMoney", minWidth: 100 },
                  { label: "门店采购数", prop: "shopPurQty", minWidth: 100 },
                  { label: "门店采购金额", prop: "shopPurMoney", minWidth: 100 },
                  { label: "门店生产数", prop: "shopProduceInQty", minWidth: 100 },
                  { label: "门店生产金额", prop: "shopProduceInMoney", minWidth: 100 },
                  { label: "门店调入数", prop: "shopMoveInQty", minWidth: 100 },
                  { label: "门店调入金额", prop: "shopMoveInMoney", minWidth: 100 },
                  { label: "门店盘盈数", prop: "shopFullQty", minWidth: 100 },
                  { label: "门店盘盈金额", prop: "shopFullMoney", minWidth: 100 },
                  {
                    label: "现烤生产数",
                    prop: "freshlyBakedProduceInQty",
                    minWidth: 100,
                  },
                  {
                    label: "现烤生产金额",
                    prop: "freshlyBakedProduceInMoney",
                    minWidth: 100,
                  },
                  { label: "现烤调入数", prop: "freshlyBakedMoveInQty", minWidth: 100 },
                  {
                    label: "现烤调入金额",
                    prop: "freshlyBakedMoveInMoney",
                    minWidth: 100,
                  },
                  { label: "现烤盘盈数", prop: "freshlyBakedFullQty", minWidth: 100 },
                  { label: "现烤盘盈金额", prop: "freshlyBakedFullMoney", minWidth: 100 },
                  { label: "裱花生产数", prop: "decorationProduceInQty", minWidth: 100 },
                  {
                    label: "裱花生产金额",
                    prop: "decorationProduceInMoney",
                    minWidth: 100,
                  },
                  { label: "裱花调入数", prop: "decorationMoveInQty", minWidth: 100 },
                  { label: "裱花调入金额", prop: "decorationMoveInMoney", minWidth: 100 },
                  { label: "裱花盘盈数", prop: "decorationFullQty", minWidth: 100 },
                  { label: "裱花盘盈金额", prop: "decorationFullMoney", minWidth: 100 },
                ],
              },
              {
                label: "出库",
                prop: "billDate",
                children: [
                  { label: "销售数", prop: "saleQty", minWidth: 100 },
                  { label: "销售金额", prop: "saleMoney", minWidth: 100 },
                  { label: "仓库调出数", prop: "moveOutQty", minWidth: 100 },
                  { label: "仓库调出金额", prop: "moveOutMoney", minWidth: 100 },
                  { label: "仓库报损数", prop: "wasteQty", minWidth: 100 },
                  { label: "仓库报损金额", prop: "wasteMoney", minWidth: 100 },
                  { label: "库存调整出库数", prop: "adjustOutQty", minWidth: 120 },
                  { label: "库存调整出库金额", prop: "adjustOutMoney", minWidth: 130 },
                  { label: "采购退货数", prop: "purBackQty", minWidth: 100 },
                  { label: "采购退货金额", prop: "purBackMoney", minWidth: 100 },
                  { label: "仓库盘亏数", prop: "loseQty", minWidth: 100 },
                  { label: "仓库盘亏金额", prop: "loseMoney", minWidth: 100 },
                  { label: "生产领料数", prop: "leadMaterialQty", minWidth: 100 },
                  { label: "生产领料金额", prop: "leadMaterialMoney", minWidth: 100 },

                  { label: "组装出库数", prop: "combinOutQty", minWidth: 120 },
                  { label: "组装出库金额", prop: "combinOutMoney", minWidth: 120 },
                  { label: "拆卸出库数", prop: "stripOutQty", minWidth: 130 },
                  { label: "拆卸出库金额", prop: "stripOutMoney", minWidth: 130 },
                  { label: "门店销售数", prop: "shopSaleQty", minWidth: 100 },
                  { label: "门店销售金额", prop: "shopSaleMoney", minWidth: 100 },
                  { label: "门店报废数", prop: "shopWasteQty", minWidth: 100 },
                  { label: "门店报废金额", prop: "shopWasteMoney", minWidth: 100 },
                  { label: "门店调出数", prop: "shopMoveOutQty", minWidth: 120 },
                  { label: "门店调出金额", prop: "shopMoveOutMoney", minWidth: 120 },
                  { label: "门店原料消耗数", prop: "shopLeadMaterialQty", minWidth: 130 },
                  {
                    label: "门店原料消耗金额",
                    prop: "shopLeadMaterialMoney",
                    minWidth: 130,
                  },
                  { label: "门店盘亏数", prop: "shopLoseQty", minWidth: 100 },
                  { label: "门店盘亏金额", prop: "shopLoseMoney", minWidth: 100 },
                  { label: "门店零售数", prop: "shopRetailQty", minWidth: 100 },
                  { label: "门店零售金额", prop: "shopRetailMoney", minWidth: 100 },
                  { label: "现烤报废数", prop: "freshlyBakedWasteQty", minWidth: 100 },
                  {
                    label: "现烤报废金额",
                    prop: "freshlyBakedWasteMoney",
                    minWidth: 100,
                  },
                  { label: "现烤调出数", prop: "freshlyBakedMoveOutQty", minWidth: 100 },
                  {
                    label: "现烤调出金额",
                    prop: "freshlyBakedMoveOutMoney",
                    minWidth: 100,
                  },
                  {
                    label: "现烤原料消耗数",
                    prop: "freshlyBakedLeadMaterialQty",
                    minWidth: 130,
                  },
                  {
                    label: "现烤原料消耗金额",
                    prop: "freshlyBakedLeadMaterialMoney",
                    minWidth: 130,
                  },
                  { label: "现烤盘亏数", prop: "freshlyBakedLoseQty", minWidth: 100 },
                  { label: "现烤盘亏金额", prop: "freshlyBakedLoseMoney", minWidth: 100 },
                  { label: "裱花报废数", prop: "decorationWasteQty", minWidth: 100 },
                  { label: "裱花报废金额", prop: "decorationWasteMoney", minWidth: 100 },
                  { label: "裱花调出数", prop: "decorationMoveOutQty", minWidth: 100 },
                  {
                    label: "裱花调出金额",
                    prop: "decorationMoveOutMoney",
                    minWidth: 100,
                  },
                  {
                    label: "裱花原料消耗数",
                    prop: "decorationLeadMaterialQty",
                    minWidth: 130,
                  },
                  {
                    label: "裱花原料消耗金额",
                    prop: "decorationLeadMaterialMoney",
                    minWidth: 130,
                  },
                  { label: "裱花盘亏数", prop: "decorationLoseQty", minWidth: 100 },
                  { label: "裱花盘亏金额", prop: "decorationLoseMoney", minWidth: 100 },
                ],
              },
            ],
            summary: [
              "firstQty",
              "firstMoney",
              "inQty",
              "inMoney",
              "outQty",
              "outMoney",
              "finalQty",
              "finalMoney",
              "purQty",
              "purMoney",
              "moveInQty",
              "moveInMoney",
              "adjustInQty",
              "adjustInMoney",
              "saleBackQty",
              "saleBackMoney",
              "fullQty",
              "fullMoney",
              "backMaterialQty",
              "backMaterialMoney",
              "produceInQty",
              "produceInMoney",
              "combinInQty",
              "combinInMoney",
              "stripInQty",
              "stripInMoney",
              "shopPurQty",
              "shopPurMoney",
              "shopProduceInQty",
              "shopProduceInMoney",
              "shopMoveInQty",
              "shopMoveInMoney",
              "shopFullQty",
              "shopFullMoney",
              "freshlyBakedProduceInQty",
              "freshlyBakedProduceInMoney",
              "freshlyBakedMoveInQty",
              "freshlyBakedMoveInMoney",
              "freshlyBakedFullQty",
              "freshlyBakedFullMoney",
              "decorationProduceInQty",
              "decorationProduceInMoney",
              "decorationMoveInQty",
              "decorationMoveInMoney",
              "decorationFullQty",
              "decorationFullMoney",
              "saleQty",
              "saleMoney",
              "moveOutQty",
              "moveOutMoney",
              "adjustOutQty",
              "adjustOutMoney",
              "purBackQty",
              "purBackMoney",
              "loseQty",
              "loseMoney",
              "leadMaterialQty",
              "leadMaterialMoney",
              "combinOutQty",
              "combinOutMoney",
              "stripOutQty",
              "stripOutMoney",
              "shopSaleQty",
              "shopSaleMoney",
              "shopWasteQty",
              "shopWasteMoney",
              "shopMoveOutQty",
              "shopMoveOutMoney",
              "shopLeadMaterialQty",
              "shopLeadMaterialMoney",
              "shopLoseQty",
              "shopLoseMoney",
              "shopRetailQty",
              "shopRetailMoney",
              "freshlyBakedWasteQty",
              "freshlyBakedWasteMoney",
              "freshlyBakedMoveOutQty",
              "freshlyBakedMoveOutMoney",
              "freshlyBakedLeadMaterialQty",
              "freshlyBakedLeadMaterialMoney",
              "freshlyBakedLoseQty",
              "freshlyBakedLoseMoney",
              "decorationWasteQty",
              "decorationWasteMoney",
              "decorationMoveOutQty",
              "decorationMoveOutMoney",
              "decorationLeadMaterialQty",
              "decorationLeadMaterialMoney",
              "decorationLoseQty",
              "decorationLoseMoney",
            ],
          },
          {
            title: "商品每日库存查询",
            type: "day",
            getListApi: listBookStatisticsByStoreGoodsDay,
            getSummaryApi: sumListBookStatisticsByStoreGoodsDay,
            exportOption: {
              show: true,
              exportApi: "exportListBookStatisticsByStoreDay",
              exportName: "商品每日库存查询一览表",
              timeout: 60 * 5 * 1000,
            },
            columns: [
              {
                prop: "dayDate",
                label: "单据日期",
                minWidth: 120,
                fixed: true,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
                formatter: (v, row) => row.storeInfo.storeName,
                fixed: true,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsNo,
                fixed: true,
                link: true,
                click: "oepnDetail",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsName,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.barcode,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
                formatter: (v, row) => row.goodsCategoryInfo.categoryName,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsSpec,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.unitName,
              },
              { label: "期初数", prop: "firstQty", minWidth: 120 },
              { label: "期初金额", prop: "firstMoney", minWidth: 120 },
              { label: "入库数", prop: "inQty", minWidth: 120 },
              { label: "入库金额", prop: "inMoney", minWidth: 120 },
              { label: "出库数", prop: "outQty", minWidth: 120 },
              { label: "出库金额", prop: "outMoney", minWidth: 120 },
              { label: "期末数", prop: "finalQty", minWidth: 120 },
              { label: "期末金额", prop: "finalMoney", minWidth: 120 },
              {
                label: "入库",
                prop: "billDate",
                children: [
                  { label: "采购数", prop: "purQty", minWidth: 100 },
                  { label: "采购金额", prop: "purMoney", minWidth: 100 },
                  { label: "仓库调入数", prop: "moveInQty", minWidth: 100 },
                  { label: "仓库调入金额", prop: "moveInMoney", minWidth: 100 },
                  { label: "库存调整入库数", prop: "adjustInQty", minWidth: 120 },
                  { label: "库存调整入库金额", prop: "adjustInMoney", minWidth: 130 },
                  { label: "销售退货数", prop: "saleBackQty", minWidth: 100 },
                  { label: "销售退货金额", prop: "saleBackMoney", minWidth: 100 },
                  { label: "仓库盘盈数", prop: "fullQty", minWidth: 100 },
                  { label: "仓库盘盈金额", prop: "fullMoney", minWidth: 100 },
                  { label: "生产退料数", prop: "backMaterialQty", minWidth: 100 },
                  { label: "生产退料金额", prop: "backMaterialMoney", minWidth: 100 },
                  { label: "产品入库数", prop: "produceInQty", minWidth: 100 },
                  { label: "产品入库金额", prop: "produceInMoney", minWidth: 100 },

                  { label: "组装入库数", prop: "combinInQty", minWidth: 130 },
                  { label: "组装入库金额", prop: "combinInMoney", minWidth: 130 },
                  { label: "拆卸入库数", prop: "stripInQty", minWidth: 100 },
                  { label: "拆卸入库金额", prop: "stripInMoney", minWidth: 100 },
                  { label: "门店采购数", prop: "shopPurQty", minWidth: 100 },
                  { label: "门店采购金额", prop: "shopPurMoney", minWidth: 100 },
                  { label: "门店生产数", prop: "shopProduceInQty", minWidth: 100 },
                  { label: "门店生产金额", prop: "shopProduceInMoney", minWidth: 100 },
                  { label: "门店调入数", prop: "shopMoveInQty", minWidth: 100 },
                  { label: "门店调入金额", prop: "shopMoveInMoney", minWidth: 100 },
                  { label: "门店盘盈数", prop: "shopFullQty", minWidth: 100 },
                  { label: "门店盘盈金额", prop: "shopFullMoney", minWidth: 100 },
                  {
                    label: "现烤生产数",
                    prop: "freshlyBakedProduceInQty",
                    minWidth: 100,
                  },
                  {
                    label: "现烤生产金额",
                    prop: "freshlyBakedProduceInMoney",
                    minWidth: 100,
                  },
                  { label: "现烤调入数", prop: "freshlyBakedMoveInQty", minWidth: 100 },
                  {
                    label: "现烤调入金额",
                    prop: "freshlyBakedMoveInMoney",
                    minWidth: 100,
                  },
                  { label: "现烤盘盈数", prop: "freshlyBakedFullQty", minWidth: 100 },
                  { label: "现烤盘盈金额", prop: "freshlyBakedFullMoney", minWidth: 100 },
                  { label: "裱花生产数", prop: "decorationProduceInQty", minWidth: 100 },
                  {
                    label: "裱花生产金额",
                    prop: "decorationProduceInMoney",
                    minWidth: 100,
                  },
                  { label: "裱花调入数", prop: "decorationMoveInQty", minWidth: 100 },
                  { label: "裱花调入金额", prop: "decorationMoveInMoney", minWidth: 100 },
                  { label: "裱花盘盈数", prop: "decorationFullQty", minWidth: 100 },
                  { label: "裱花盘盈金额", prop: "decorationFullMoney", minWidth: 100 },
                ],
              },
              {
                label: "出库",
                prop: "billDate",
                children: [
                  { label: "销售数", prop: "saleQty", minWidth: 100 },
                  { label: "销售金额", prop: "saleMoney", minWidth: 100 },
                  { label: "仓库调出数", prop: "moveOutQty", minWidth: 100 },
                  { label: "仓库调出金额", prop: "moveOutMoney", minWidth: 100 },
                  { label: "仓库报损数", prop: "wasteQty", minWidth: 100 },
                  { label: "仓库报损金额", prop: "wasteMoney", minWidth: 100 },
                  { label: "库存调整出库数", prop: "adjustOutQty", minWidth: 120 },
                  { label: "库存调整出库金额", prop: "adjustOutMoney", minWidth: 130 },
                  { label: "采购退货数", prop: "purBackQty", minWidth: 100 },
                  { label: "采购退货金额", prop: "purBackMoney", minWidth: 100 },
                  { label: "仓库盘亏数", prop: "loseQty", minWidth: 100 },
                  { label: "仓库盘亏金额", prop: "loseMoney", minWidth: 100 },
                  { label: "生产领料数", prop: "leadMaterialQty", minWidth: 100 },
                  { label: "生产领料金额", prop: "leadMaterialMoney", minWidth: 100 },

                  { label: "组装出库数", prop: "combinOutQty", minWidth: 120 },
                  { label: "组装出库金额", prop: "combinOutMoney", minWidth: 120 },
                  { label: "拆卸出库数", prop: "stripOutQty", minWidth: 130 },
                  { label: "拆卸出库金额", prop: "stripOutMoney", minWidth: 130 },
                  { label: "门店销售数", prop: "shopSaleQty", minWidth: 100 },
                  { label: "门店销售金额", prop: "shopSaleMoney", minWidth: 100 },
                  { label: "门店报废数", prop: "shopWasteQty", minWidth: 100 },
                  { label: "门店报废金额", prop: "shopWasteMoney", minWidth: 100 },
                  { label: "门店调出数", prop: "shopMoveOutQty", minWidth: 120 },
                  { label: "门店调出金额", prop: "shopMoveOutMoney", minWidth: 120 },
                  { label: "门店原料消耗数", prop: "shopLeadMaterialQty", minWidth: 130 },
                  {
                    label: "门店原料消耗金额",
                    prop: "shopLeadMaterialMoney",
                    minWidth: 130,
                  },
                  { label: "门店盘亏数", prop: "shopLoseQty", minWidth: 100 },
                  { label: "门店盘亏金额", prop: "shopLoseMoney", minWidth: 100 },
                  { label: "门店零售数", prop: "shopRetailQty", minWidth: 100 },
                  { label: "门店零售金额", prop: "shopRetailMoney", minWidth: 100 },
                  { label: "现烤报废数", prop: "freshlyBakedWasteQty", minWidth: 100 },
                  {
                    label: "现烤报废金额",
                    prop: "freshlyBakedWasteMoney",
                    minWidth: 100,
                  },
                  { label: "现烤调出数", prop: "freshlyBakedMoveOutQty", minWidth: 100 },
                  {
                    label: "现烤调出金额",
                    prop: "freshlyBakedMoveOutMoney",
                    minWidth: 100,
                  },
                  {
                    label: "现烤原料消耗数",
                    prop: "freshlyBakedLeadMaterialQty",
                    minWidth: 130,
                  },
                  {
                    label: "现烤原料消耗金额",
                    prop: "freshlyBakedLeadMaterialMoney",
                    minWidth: 130,
                  },
                  { label: "现烤盘亏数", prop: "freshlyBakedLoseQty", minWidth: 100 },
                  { label: "现烤盘亏金额", prop: "freshlyBakedLoseMoney", minWidth: 100 },
                  { label: "裱花报废数", prop: "decorationWasteQty", minWidth: 100 },
                  { label: "裱花报废金额", prop: "decorationWasteMoney", minWidth: 100 },
                  { label: "裱花调出数", prop: "decorationMoveOutQty", minWidth: 100 },
                  {
                    label: "裱花调出金额",
                    prop: "decorationMoveOutMoney",
                    minWidth: 100,
                  },
                  {
                    label: "裱花原料消耗数",
                    prop: "decorationLeadMaterialQty",
                    minWidth: 130,
                  },
                  {
                    label: "裱花原料消耗金额",
                    prop: "decorationLeadMaterialMoney",
                    minWidth: 130,
                  },
                  { label: "裱花盘亏数", prop: "decorationLoseQty", minWidth: 100 },
                  { label: "裱花盘亏金额", prop: "decorationLoseMoney", minWidth: 100 },
                ],
              },
            ],
            summary: [
              "firstQty",
              "firstMoney",
              "inQty",
              "inMoney",
              "outQty",
              "outMoney",
              "finalQty",
              "finalMoney",
              "purQty",
              "purMoney",
              "moveInQty",
              "moveInMoney",
              "adjustInQty",
              "adjustInMoney",
              "saleBackQty",
              "saleBackMoney",
              "fullQty",
              "fullMoney",
              "backMaterialQty",
              "backMaterialMoney",
              "produceInQty",
              "produceInMoney",
              "combinInQty",
              "combinInMoney",
              "stripInQty",
              "stripInMoney",
              "shopPurQty",
              "shopPurMoney",
              "shopProduceInQty",
              "shopProduceInMoney",
              "shopMoveInQty",
              "shopMoveInMoney",
              "shopFullQty",
              "shopFullMoney",
              "freshlyBakedProduceInQty",
              "freshlyBakedProduceInMoney",
              "freshlyBakedMoveInQty",
              "freshlyBakedMoveInMoney",
              "freshlyBakedFullQty",
              "freshlyBakedFullMoney",
              "decorationProduceInQty",
              "decorationProduceInMoney",
              "decorationMoveInQty",
              "decorationMoveInMoney",
              "decorationFullQty",
              "decorationFullMoney",
              "saleQty",
              "saleMoney",
              "moveOutQty",
              "moveOutMoney",
              "adjustOutQty",
              "adjustOutMoney",
              "purBackQty",
              "purBackMoney",
              "loseQty",
              "loseMoney",
              "leadMaterialQty",
              "leadMaterialMoney",
              "combinOutQty",
              "combinOutMoney",
              "stripOutQty",
              "stripOutMoney",
              "shopSaleQty",
              "shopSaleMoney",
              "shopWasteQty",
              "shopWasteMoney",
              "shopMoveOutQty",
              "shopMoveOutMoney",
              "shopLeadMaterialQty",
              "shopLeadMaterialMoney",
              "shopLoseQty",
              "shopLoseMoney",
              "shopRetailQty",
              "shopRetailMoney",
              "freshlyBakedWasteQty",
              "freshlyBakedWasteMoney",
              "freshlyBakedMoveOutQty",
              "freshlyBakedMoveOutMoney",
              "freshlyBakedLeadMaterialQty",
              "freshlyBakedLeadMaterialMoney",
              "freshlyBakedLoseQty",
              "freshlyBakedLoseMoney",
              "decorationWasteQty",
              "decorationWasteMoney",
              "decorationMoveOutQty",
              "decorationMoveOutMoney",
              "decorationLeadMaterialQty",
              "decorationLeadMaterialMoney",
              "decorationLoseQty",
              "decorationLoseMoney",
            ],
          },
        ],
      },
      optionsDetail: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: "d4edb18a-4616-27b6-9832-70a200444f51",
        body: {},
        title: "商品库存明细",
        tableTitle: "商品库存明细",
        list: [],
        search: [
          {
            type: "input",
            label: "仓库",
            tip: " ",
            model: "",
            filter: "storeName",
            disabled: true,
          },
          {
            type: "input",
            label: "商品编码",
            tip: " ",
            model: "",
            filter: "goodsNo",
            disabled: true,
          },
          {
            type: "input",
            label: "商品名称",
            tip: " ",
            model: "",
            filter: "goodsName",
            disabled: true,
          },
          {
            type: "input",
            label: "商品条码",
            tip: " ",
            model: "",
            filter: "barcode",
            disabled: true,
          },
          {
            type: "input",
            label: "商品批号",
            tip: " ",
            model: "",
            filter: "batchNo",
            disabled: true,
          },
          {
            type: "input",
            label: "规格",
            tip: " ",
            model: "",
            filter: "goodsSpec",
            disabled: true,
          },
          {
            type: "input",
            label: "基础单位",
            tip: " ",
            model: "",
            filter: "unitName",
            disabled: true,
          },
          {
            type: "input",
            label: "成本价",
            tip: " ",
            model: "",
            filter: "costPrice",
            disabled: true,
          },
          {
            type: "input",
            label: "期初数",
            tip: " ",
            model: "",
            filter: "firstQty",
            disabled: true,
          },
          {
            type: "input",
            label: "期初金额",
            tip: " ",
            model: "",
            filter: "firstMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "入库数",
            tip: " ",
            model: "",
            filter: "inQty",
            disabled: true,
          },
          {
            type: "input",
            label: "入库金额",
            tip: " ",
            model: "",
            filter: "inMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "出库数",
            tip: " ",
            model: "",
            filter: "outQty",
            disabled: true,
          },
          {
            type: "input",
            label: "出库金额",
            tip: " ",
            model: "",
            filter: "outMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "期末数",
            tip: " ",
            model: "",
            filter: "finalQty",
            disabled: true,
          },
          {
            type: "input",
            label: "期末金额",
            tip: " ",
            model: "",
            filter: "finalMoney",
            disabled: true,
          },
          { type: "button", tip: "关闭", btnType: "primary", click: "close" },
        ],
        summary: [
          "firstQty",
          "firstMoney",
          "inQty",
          "inMoney",
          "outQty",
          "outMoney",
          "finalQty",
          "finalMoney",
        ],
        columns: [
          { label: "记账时间", prop: "auditTime", fixed: true, minWidth: 120 },
          {
            label: "单据编号",
            prop: "billNo",
            minWidth: 120,
            fixed: true,
            link: true,
            click: "getBillData",
          },
          { label: "单据日期", prop: "billDate", minWidth: 100 },
          { label: "单据类型", prop: "billTypeName", minWidth: 120 },
          { label: "商品批号", prop: "batchNo", minWidth: 100 },
          { label: "期初数", prop: "firstQty", minWidth: 80 },
          { label: "期初金额", prop: "firstMoney", minWidth: 80 },
          { label: "入库数", prop: "inQty", minWidth: 80 },
          { label: "入库金额", prop: "inMoney", minWidth: 100 },
          { label: "出库数", prop: "outQty", minWidth: 80 },
          { label: "出库金额", prop: "outMoney", minWidth: 100 },
          { label: "期末数", prop: "finalQty", minWidth: 80 },
          { label: "期末金额", prop: "finalMoney", minWidth: 100 },
          { label: "备注", prop: "remark" },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true;
          this.optionsDetail.list = [];
          break;
        case "oepnDetail": //获取点击商品信息
          let params = {};
          params.goodsId = row.goodsId;
          //当批号存在时 赋值
          if (row.batchNo) {
            params.batchNo = row.batchNo;
          }
          params.storeId = row.storeId;
          const res = await storeInventoryGoodsDetail(params);
          this.optionsDetail.list = res.data.details;
          this.optionsDetail.search = this.optionsDetail.search.map((item) => ({
            ...item,
            model: res.data.mainReport[item.filter],
          }));
          this.optionsDetail.loading = false;
          this.showList = false;
          // this.goodsDetailData = res.data; //商品详情赋值
          // this.openStockQuery = true; //打开商品详情弹出框
          break;
        case "getBillData": // 明细点击
          // let billId = row.billId
          // let billType = row.billType
          // let routerName
          // if(billType === '102') routerName = 'PurchaseInstoreDetail'
          // if(billType === '103') routerName = 'PurchaseBackDetail'
          // if(billType === '105') routerName = 'SaleOutDetail'
          // if(billType === '106') routerName = 'SaleBackDetail'
          // if(billType === '107') routerName = 'storeCheckDetail'
          // if(billType === '108') routerName = 'storeAdjustDetail'
          // if(billType === '109') routerName = 'storeWasteDetail'
          // if(billType === '110') routerName = 'storeMoveDetail'
          // if(billType === '111') routerName = 'storeMoveOutDetail'
          // if(billType === '112') routerName = 'storeMoveInDetail'
          // if(billType === '113') routerName = 'storeCheckFullDetail'
          // if(billType === '114') routerName = 'storeCheckLoseDetail'
          // this.$router.push({
          //   name:routerName,
          //   query: {
          //     billId,
          //     type: 'Update'
          //   }
          // })
          break;
        default:
          break;
      }
    },
  },
};
</script>
