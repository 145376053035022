<!-- 新增明细表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { listTransferDetail, sumListTransferDetail } from '@/api/store/report' //仓库报表
// const typesList = ['100201','110102','110103','120102','120103','130102','130103','130104','130105','130106','130107','130108','140303','140304','140305','140306','140307','140308','140310','140311','170202','170203','170205','100201',]
const typesList = ['140305','140306','130104','130105','130106', '170407', '170406', '170307', '170306']
export default {
  name: 'moveDetail',
  components: { TablePage },
  data () {
    return {
      options: {
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        uuid:'fe8487ac-d834-c64d-ed62-5f6d034994de',
        getListApi: listTransferDetail,
        getSummaryApi: sumListTransferDetail,
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        labelWidth: "80",
        check: [], // 选中数据
        // exportOption: {
        //   show: true,
        //   exportApi: 'exportListTransferDetail',
        //   exportName: '仓库调拨明细表',
        //   timeout: 60*5*1000
        // },
        exportOption: {
          show: true,
          ossKeyUrl: '/api/system/shop/report/sale/ranking/getExportGoodsInfo',
          fastExportUrl: '/api/system/store/reportForms/exportListTransferDetail',
          exportName: '仓库调拨明细表'
        },
        search: [
          {
            label: '单据日期',
            type: 'daterange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: 'daterange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: "filters",
            tip: "全部/单据编号/商品编码/商品条码/商品名称/备注",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "barcodes", label: "商品条码" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "remarks", label: "备注" },
            ],
          },
          this.$select({
            key: "billTypeList",
            option: { seniorSearch: true, option: { multiple: true, dataKey:(res)=> res.data.filter(x=> typesList.includes(x.dictValue) )} },
          }),
          this.$select({
            key: "billCustomType",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listInStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 120
          },
          {
            prop: 'billTypeName',
            label: '单据类型',
            minWidth: 120
          },
          {
            prop: 'billCustomTypeName',
            label: ' 业务类型',
            minWidth: 120
          },
          {
            prop: 'outStoreNo',
            label: '调出仓库编号',
            minWidth: 150,
          },
          {
            prop: 'outStoreName',
            label: '调出仓库',
            minWidth: 120
          },
          {
            prop: 'inStoreNo',
            label: '调入仓库编号',
            minWidth: 160
          },
          {
            prop: 'inStoreName',
            label: '调入仓库',
            minWidth: 120
          },
          {
            prop: 'categoryName',
            label: '商品类别',
            minWidth: 150
          },
          {
            prop: 'goodsNo',
            label: '商品编码',
            minWidth: 150
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 150
          },
          {
            prop: 'barcode',
            label: '商品条码',
            minWidth: 150
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 150
          },
          {
            prop: 'unitName',
            // label: '基本单位',
            label: '单位',
            minWidth: 150
          },
          {
            prop: 'unitPrice',
            label: '调拨单价',
            minWidth: 150
          },
          {
            prop: 'unitPriceTaxMoney',
            label: '调拨金额',
            minWidth: 150
          },
          {
            prop: 'moveOutQty',
            label: '调出数量',
            minWidth: 150
          },
          {
            prop: 'moveOutPurPrice',
            label: '调出进价单价',
            minWidth: 150
          },
          {
            prop: 'moveOutPurMoney',
            label: '调出进价金额',
            minWidth: 150
          },
          {
            prop: 'moveOutSalePrice',
            label: '调出售价单价',
            minWidth: 150
          },
          {
            prop: 'moveOutSaleMoney',
            label: '调出售价金额',
            minWidth: 150
          },
          {
            prop: 'moveInQty',
            label: '调入数量',
            minWidth: 150
          },
          {
            prop: 'moveInPurPrice',
            label: '调入进价单价',
            minWidth: 150
          },
          {
            prop: 'moveInPurMoney',
            label: '调入进价金额',
            minWidth: 150
          },
          {
            prop: 'moveInSalePrice',
            label: '调入售价单价',
            minWidth: 150
          },
          {
            prop: 'moveInSaleMoney',
            label: '调入售价金额',
            minWidth: 150
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 150
          },
          {
            prop: 'billRemark',
            label: '单据备注',
            minWidth: 150
          },
        ],
        summary: [ "unitPriceTaxMoney", "moveOutQty", "moveOutPurMoney", "moveOutSaleMoney", "moveInQty", "moveInPurMoney", "moveInSaleMoney" ],
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      console.log(type, row, 'type, row')
      switch (type) {
        case "routerLink":
          {
            const allRouter = {
              140305: 'MoveInDetail', // 门店调入
              140306: 'MoveOutDetail', // 门店调出
              130104: 'storeMoveDetail', // 仓库调拨单
              130105: 'storeMoveOutDetail', // 仓库调出单
              130106: 'storeMoveInDetail', // 仓库调入单
            };
            let name = allRouter[row.billType];
            if (!name) return
            this.$router.push({
              name: name,
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
    seniorSearch () {},
  }
}
</script>
<style lang="scss" scoped></style>
