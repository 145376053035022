<!-- 商品出入库单据 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { httpListBillStatisticsReport, sumListBillStatisticsReport } from '@/api/storeReport' //接口api
import { listGoods } from '@/api/goods/goods' //商品列表
import { listCategory } from '@/api/goods/category'
import { allStoreList } from '@/api/system/store' //仓库
/**
 params: {
        pageNum: 1,
        pageSize: 15,
        beginBillDate: undefined, //单据开始时间
        endBillDate: undefined, //单据结束时间
        beginBookDate: undefined, //记账开始时间
        endBookDate: undefined, //记账结束时间
        billPostType: [], //记账类型
        inOutStore: [], //出入库方式
        storeIds: [], //仓库
        categoryIds: [], //商品类别
        goodsIds: [], //商品名称
        billTypes: [] //单据类型
      },
 */

const typesList = ['100201','110102','110103','120102','120103','130102','130103','130104','130105','130106','130107','130108','140303','140304','140305','140306','140307','140308','140310','140311','170202','170203','170205','100201',]
export default {
  components: { TablePage },
  dicts: ['bill_status'],
  data () {
    return {
      options: {
        summary: ['inMoney', 'outMoney'],
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        uuid:'676fcf15-8015-8389-9137-c4f2e9d61402',
        getListApi: httpListBillStatisticsReport,
        getSummaryApi: sumListBillStatisticsReport,
        body: {},
        labelWidth: "100",
        listNo: true, // 序号
        title: '单据信息',
        check: [], // 选中数据
        rowKey: 'billId',
        exportOption: {
          show: true,
          exportApi: 'exportListBillStatisticsReport',
          exportName: '商品出入库单据',
          timeout: 60*5*1000
        },
        search: [
          {
            label: '单据日期',
            type: 'daterange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "单据编号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
            ],
          },
          {
            label: '记账日期',
            type: 'datetimerange',
            model: '',
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginBookDate: a, endBookDate: b })
          },
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "billTypeList",
            option: { seniorSearch: true, option: { multiple: true, dataKey:(res)=> res.data.filter(x=> typesList.includes(x.dictValue) )} },
          }),
          this.$select({
            key: "listBillPostTypes",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "inOutStoreList",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          {
            label: '单据编号',
            prop: 'billNo',
            type: 'link',
            click: 'routerLink'
          },
          { label: '单据日期', prop: 'billDate' },
          { label: '入库金额', prop: 'inMoney' },
          { label: '出库金额', prop: 'outMoney' },
          { label: '单据类型', prop: 'billTypeName' }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'routerLink':
          {
            let billId = row.billId
            let billType = row.billType
            let routerName
            if (billType === '102') routerName = 'PurchaseInstoreDetail'
            if (billType === '103') routerName = 'PurchaseBackDetail'
            if (billType === '105') routerName = 'SaleOutDetail'
            if (billType === '106') routerName = 'SaleBackDetail'
            if (billType === '107') routerName = 'storeCheckDetail'
            if (billType === '108') routerName = 'storeAdjustDetail'
            if (billType === '109') routerName = 'storeWasteDetail'
            if (billType === '110') routerName = 'storeMoveDetail'
            if (billType === '111') routerName = 'storeMoveOutDetail'
            if (billType === '112') routerName = 'storeMoveInDetail'
            if (billType === '113') routerName = 'storeCheckFullDetail'
            if (billType === '114') routerName = 'storeCheckLoseDetail'
            this.$router.push({
              name: routerName,
              query: {
                billId,
                type: 'Update'
              }
            })
          }
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
