<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { allStoreList } from '@/api/system/store' //仓库
import { updateInstoreStatus } from '@/api/purchase/index'
import { httpAdjustList } from '@/api/store/index'

const { cloneDeep } = require('lodash')

export default {
  name: 'unsaleGoods',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        uuid:'1764c47c-42b2-8292-f9c1-d2fc0c3208ac',
        getListApi: httpAdjustList,
        body: {},
        defaultBody: {},
        listNo: true, // 序号
        title: '单据信息',
        check: [], // 选中数据
        rowKey: 'billId',
        search: [
          {
            label: '单据日期',
            type: 'daterange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: 'daterange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            label: '单据编号',
            seniorSearch: true,
            type: 'input',
            model: '',
            filter: 'billNo'
          },
          this.$select({
            key: "listStoreName",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "dataInOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "dataBillStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            disabled: false
          },
          {
            click: 'audit',
            label: '审核',
            type: 'success',
            btnType: 'dropdown',
            disabled: false,
            other: [
              {
                click: 'redoAudit',
                label: '反审核',
                disabled: false
              }
            ]
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: false
          },
          {
            click: 'stop',
            label: '终止',
            icon: 'el-icon-remove-outline',
            type: 'primary',
            disabled: false
          }
        ],
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: 'link',
            click: 'routerLink',
            minWidth: 180
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 120
          },
          {
            prop: 'billStatusName',
            label: '单据状态',
            // type: 'dict',
            minWidth: 120,
            // dict: 'bill_status'
          },

          {
            prop: 'storeName',
            label: '仓库名称',
            minWidth: 160
          },
          {
            prop: 'billPostTypeName',
            label: '调整方式',
            minWidth: 120
          },
          {
            prop: 'scrapTypeValue',
            label: '调整原因',
            minWidth: 120
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 120
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 160
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      const selectData = this.options.check
      let url = '/api/system/store/bill/adjust/updateBillStatus'
      let billType = '108'
      switch (type) {
        case 'routerLink':
          {
            const billId = row.billId
            this.$router.push({
              name: 'storeAdjustDetail',
              query: {
                billId: billId,
                type: 'Update'
              }
            })
          }
          break
        case 'add':
          {
            this.$router.push({
              name: 'storeAdjustDetail',
              query: {
                type: 'Add'
              }
            })
          }
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData, url, billType)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', this.options.check, url, billType)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData, url, billType)
          }
          break
        case 'stop':
          {
            if (!selectData.length) return
            this.handleBillStatus('关闭', selectData, url, billType)
          }
          break
        case 'getListed':
          {
            this.options.body.billStatus = undefined
          }
          break
        default:
          break
      }
    },
    seniorSearch () {},
    handleBillStatus (command, selectData, url, BillType) {
      let billData = [] //过滤的数据
      let name = '' //要执行的状态名字
      let tag = '' //提示的状态
      let billStatus = '' //要执行的状态数字
      if (command === '反审核') {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = '反审核'
        tag = '已审核'
        billStatus = '0'
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === '审核') {
          name = '审核'
          tag = '未审核'
          billStatus = '2'
        } else if (command === '删除') {
          name = '删除'
          tag = '未审核'
          billStatus = '3'
        } else if (command === '关闭') {
          name = '关闭'
          tag = '未审核'
          billStatus = '4'
        }
      }
      const billId = selectData.map(i => i.billId)
      const billNo = selectData.map(i => i.billNo)
      const $this = this
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus(
            [...billId],
            billStatus,
            url,
            BillType
          ).then(() => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            $this.$modal.msgSuccess(`${name}成功`)
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
          })
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == '/index') {
        vm.options.defaultBody = { includeBillStatus: [to.query.billStatus] }
        vm.options.search[5].model = [to.query.billStatus]
        let cloneData = cloneDeep(vm.options)
        vm.options = null
        vm.options = cloneData
      }
    })
  }
}
</script>
<style lang="scss" scoped></style>
